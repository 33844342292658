import { theme as defaultTheme } from '@chakra-ui/core';

const theme = {
  ...defaultTheme,
  fonts: {
    body: "'Open Sans', sans-serif",
    heading: 'Montserrat, sans-serif',
    monospace: "'IBM Plex Mono', monospace",
  },
};

export default theme;
