module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-chakra-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-58311516-2","head":true,"defer":true,"anonymize":false,"respectDNT":false,"pageTransitionDelay":0,"exclude":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
